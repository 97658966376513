import { useIsInViewport } from '@kaliber/use-is-in-viewport'

import { useKeenSliderRefs } from '/machinery/useKeenSliderRefs'

import { TitleWithDescription } from '/features/buildingBlocks/TitleWithDescription'
import { SliderButtons } from '/features/pageOnly/SliderButtons'

import styles from './SliderWithText.css'

export function SliderWithText({ title, description, interactionTitle, children }) {
  return (
    <div className={styles.component}>
      {title && <TitleWithDescription {...{ title, description }} />}

      <Slider {...{ interactionTitle }}>
        {React.Children.map(children, (child) => child)}
      </Slider>
    </div>
  )
}

function Slider({ interactionTitle, children }) {
  const { ref: visibilityRef, wasInViewport } = useIsInViewport({ rootMargin: '-25%' })

  const initialSlide = 0

  const [currentSlide, setCurrentSlide] = React.useState(initialSlide)
  const [reachedEnd, setReachedEnd] = React.useState(true)

  const { sliderRef, instanceRef, isMounted } = useKeenSliderRefs({
    onDetailsChanged: handleDetailsChanged,
    interactionTitle,
    slidesNum: React.Children.count(children),
    slidesPerView: 'auto',
    spacing: 20,
    initialSlide,
    origin: 0,
  })

  const isVisible = isMounted && wasInViewport
  const hasButtons = !(currentSlide === 0 && reachedEnd) && isMounted

  return (
    <div ref={visibilityRef} className={styles.componentSlider}>
      <div ref={sliderRef} className={cx(styles.slider, isVisible && styles.isVisible, 'keen-slider')}>
        {React.Children.map(children, (child, i) => (
          <div key={i} className={cx(styles.slideContainer, 'keen-slider__slide')}>
            <div style={{ '--index': i }} className={styles.slideWrapper}>
              {child}
            </div>
          </div>
        ))}
      </div>

      {hasButtons && (
        <SliderButtons {...{ interactionTitle, currentSlide, reachedEnd, handleClick }} />
      )}
    </div>
  )

  function handleDetailsChanged(details) {
    const { rel, maxIdx } = details
    const reachedEndNew = !maxIdx || rel === maxIdx

    setCurrentSlide(rel)
    setReachedEnd(reachedEndNew)
  }

  function handleClick(idx) {
    instanceRef.current?.moveToIdx(idx)
  }
}
