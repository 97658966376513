import { useTrail, animated } from '@react-spring/web'
import { useIsInViewport } from '@kaliber/use-is-in-viewport'
import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'

import { routeMap } from '/routeMap'

import { ContainerXl } from '/features/buildingBlocks/Container'
import { TitleWithDescription } from '/features/buildingBlocks/TitleWithDescription'
import { SliderWithText } from '/features/pageOnly/SliderWithText'

import styles from './FeaturedLabels.css'


export function FeaturedLabels({ title, description, items }) {
  return (
    <section data-x='featured-labels' data-style-context='white' className={styles.component}>
      <ContainerXl>
        {Boolean(items?.length) && (
          <>
            <FeaturedLabelsGrid {...{ title, description, items }} />

            <div className={styles.sliderContainer}>
              <FeaturedLabelsSlider {...{ title, description, items }} />
            </div>
          </>
        )}
      </ContainerXl>
    </section>
  )
}

function FeaturedLabelsGrid({ title, description, items }) {
  const { ref: visibilityRef, wasInViewport } = useIsInViewport()

  const trail = useTrail(items.length, {
    opacity: wasInViewport ? 1 : 0,
    x: wasInViewport ? '0px' : '20px',
    config: { mass: 0.5, tension: 400, friction: 50, precision: 0.01, velocity: 0 }
  })

  return (
    <div className={styles.componentGrid}>
      {title && <TitleWithDescription {...{ title, description }} />}

      <div ref={visibilityRef} className={styles.grid}>
        {trail.map((props, index) => {
          const label = items[index]

          if (!label) return null

          const href = getLabelPageLink(label)

          return (
            <animated.div key={index} style={props}>
              {label.logo && (
                <Card
                  logo={label.logo}
                  expertises={label.expertises}
                  sectors={label.sectors}
                  brandColors={label.brandColors}
                  {...{ href }}
                />
              )}
            </animated.div>
          )
        })}
      </div>
    </div>
  )
}

export function FeaturedLabelsSlider({ title, description, items }) {
  return (
    <div className={styles.componentSlider}>
      <SliderWithText interactionTitle='labels-slider' {...{ title, description }}>
        {items.map((label, i) => {
          if (!label) return null

          const href = getLabelPageLink(label)

          return (
            <Card
              key={i}
              dataXContext={label}
              logo={label.logo}
              expertises={label.expertises}
              sectors={label.sectors}
              brandColors={label.brandColors}
              {...{ href }}
            />
          )
        })}
      </SliderWithText>
    </div>
  )
}

function Card({ href, logo, expertises, sectors, brandColors, dataXContext = undefined }) {
  const tags = [
    ...(expertises?.length ? expertises : []),
    ...(sectors?.length ? sectors : [])
  ].slice(0, 3)

  return href ?
    <a
      data-x='link-to-label'
      data-x-context={dataXContext}
      style={{
        '--dynamic-background-color': brandColors?.backgroundColor,
        '--dynamic-text-color': brandColors?.textColor
      }}
      className={cx(styles.componentCard, styles.component_rootCard)}
      {...{ href }}
    >
      {logo?.asset && <Logo layoutClassName={styles.logoLayout} {...{ logo }} />}
      {Boolean(tags?.length) && <Tags {...{ tags }} />}
    </a> :
    <span
      style={{
        '--dynamic-background-color': brandColors?.backgroundColor,
        '--dynamic-text-color': brandColors?.textColor
      }}
      className={cx(styles.componentCard, styles.component_rootCard)}>
      {logo?.asset && <Logo layoutClassName={styles.logoLayout} {...{ logo }} />}
      {Boolean(tags?.length) && <Tags {...{ tags }} />}
    </span>

}

function Logo({ logo, layoutClassName }) {
  return (
    <div style={{ maskImage: `url(${logo.asset.url})` }} className={cx(styles.componentLogo, layoutClassName)} />
  )
}

function Tags({ tags }) {
  return (
    <div className={styles.componentTags}>
      <ul className={styles.tagsContainer}>
        {tags?.map(({ title: label }, i) => (
          label && <Tag key={i} layoutClassName={styles.tagLayout} {...{ label }} />
        ))}
      </ul>
    </div>
  )
}

function Tag({ label, layoutClassName }) {
  return (
    <li className={cx(styles.componentTag, layoutClassName)}>
      {label}
    </li>
  )
}

function getLabelPageLink(label) {
  return label?.link
    ? label?.link
    : label?.page ? determineDocumentPathSync({ document: label.page, routeMap }) : null
}
