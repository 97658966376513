import { ContentColumns } from '/features/buildingBlocks/ContentColumns'
import { HeadingMd } from '/features/buildingBlocks/Heading'
import { PortableTextComponent } from '/features/buildingBlocks/PortableTextComponent'

import styles from './TitleWithDescription.css'

export function TitleWithDescription({ title, description }) {
  return (
    <ContentColumns
      renderSmallColumn={({ layoutClassName }) => <Title {...{ title, layoutClassName }} />}
      renderLargeColumn={({ layoutClassName }) => description && <PortableTextComponent value={description} {...{ layoutClassName }} />}
    />
  )
}

function Title({ title, layoutClassName }) {
  return (
    <div className={cx(styles.componentTitle, layoutClassName)}>
      <HeadingMd h={2} {...{ title }} />
    </div>
  )
}
